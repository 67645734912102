<template>
  <div>
    <Menu :loginInfo="loginInfo" />
    <h2 class="my-4" v-if="loginInfo.isAdmin">담당자 수정</h2>
    <h2 class="my-4" v-if="!loginInfo.isAdmin">기타 설정</h2>
    <h3 class="my-3" v-if="loginInfo.isAdmin">담당자 정보 수정</h3>
    <h3 class="my-3" v-if="!loginInfo.isAdmin">링크 설정</h3>
    <div v-if="loginInfo.isAdmin" class="row">
      <div class="col mt-4">
        <label class="text-secondary">담당자 코드</label>
        <input class="form-control" v-model="managerEdit.manager.id" />
      </div>
    </div>
    <div v-if="loginInfo.isAdmin" class="row">
      <div class="col mt-4">
        <label class="text-secondary">관리자 메모</label>
        <input class="form-control" v-model="managerEdit.manager.admin_memo" />
      </div>
    </div>
    <div class="row">
      <div class="col mt-4">
        <label class="text-secondary">쿠폰 구매방법 링크</label>
        <input class="form-control" v-model="managerEdit.manager.commercial_url" />
        <div v-if="loginInfo.isAdmin" class="form-check">
          <input class="form-check-input" type="checkbox" v-model="managerEdit.manager.commercial_is_direct" id="commercial_is_direct">
          <label class="form-check-label" for="commercial_is_direct">직접 링크</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-4">
        <label class="text-secondary">이용방법 안내 링크</label>
        <input class="form-control" v-model="managerEdit.manager.guide_url" />
        <div v-if="loginInfo.isAdmin" class="form-check">
          <input class="form-check-input" type="checkbox" v-model="managerEdit.manager.guide_is_direct" id="guide_is_direct">
          <label class="form-check-label" for="guide_is_direct">직접 링크</label>
        </div>
      </div>
    </div>
    <div v-if="managerEdit.editOk" class="row">
      <div class="col">
        <div class="alert alert-info">담당자 정보를 수정했습니다.</div>
      </div>
    </div>
    <div class="row" :class="{ 'mt-5': !loginInfo.isAdmin }">
      <div class="col text-center">
        <button class="btn btn-primary" @click="managerEditClick">수정</button>
      </div>
    </div>
    <hr class="my-5" />
    <h3 class="mb-4">로그인 비밀번호 <span v-if="loginInfo.isAdmin">초기화</span><span v-if="!loginInfo.isAdmin">변경</span></h3>
    <div class="row">
      <div class="col">
        <input class="form-control" :type="loginInfo.isAdmin ? undefined : 'password'" v-model="managerEdit.pw" />
        <small class="text-muted" v-if="loginInfo.isAdmin">이전 비밀번호는 알 수 없으며 위에 입력한 비밀번호로 덮어쓰는 초기화만 가능합니다.</small>
      </div>
    </div>
    <div v-if="managerEdit.overrideOk" class="row">
      <div class="col">
        <div class="alert alert-info">비밀번호를 초기화했습니다.</div>
      </div>
    </div>
    <div class="row mb-5 mt-2">
      <div class="col text-center">
        <button class="btn btn-primary" @click="managerPwOverrideClick"><span v-if="loginInfo.isAdmin">초기화</span><span v-if="!loginInfo.isAdmin">변경</span></button>
      </div>
    </div>
    <hr class="my-5" />
    <h3 class="mb-4">담당자 텔레그램 계정 인증</h3>
    <div class="row">
      <div class="col">
        <small class="text-muted">아래 코드를 텔레그램에 복사-붙여넣기하여 인증합니다.</small>
        <input class="form-control" readonly v-model="managerEdit.certification" />
      </div>
    </div>
    <div class="row mt-3 mb-5">
      <div v-if="!managerEdit.guest" class="col">
        현재 인증된 계정이 없습니다. 인증 후 새로고침하여 확인바랍니다.
      </div>
      <div v-if="managerEdit.guest" class="col">
        현재 인증되어 있는 텔레그램 계정: {{managerEdit.guest}}
      </div>
    </div>
    <hr v-if="loginInfo.isAdmin" />
    <div v-if="loginInfo.isAdmin" class="row my-5">
      <div class="col text-center">
        <a class="btn btn-primary" href="/manager-1.html">돌아가기</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import Menu from '@/components/Menu.vue';

export default {
  components: {
    Menu
  },
  props: {
    urlManager: String
  },
  setup(props) {
    const loginInfo = reactive({});
    const managerEdit = reactive({
      prevId: props.urlManager,
      manager: {}
    });

    (async () => {
      try{
        let res = await backend({
          action: 'managerInfo',
          id: props.urlManager
        });

        if(await loginCheck(loginInfo, res)) return;
        if(loginInfo.isAdmin) managerEdit.pw = '1234';
        if(!res.managerInfo) return location.href = '/manager-1.html';
        for(let s of ['commercial', 'guide']) {
          let p = s + '_is_direct';
          res.managerInfo[p] = res.managerInfo[p] == '1';
        }
        managerEdit.manager = res.managerInfo;
        managerEdit.certification = '/manager_certification_' + managerEdit.manager.certification;
        managerEdit.guest = res.guest;
        if(managerEdit.guest === null || managerEdit.guest == '') managerEdit.guest = '(이름없는 텔레그램 계정)';

      }catch (e){
        console.log(e);
      }
    })();

    const managerEditClick = async () =>{
      try{
        if(!(await backend({
          action: 'managerEdit',
          prevId: managerEdit.prevId,
          manager: managerEdit.manager
        })).isError){
          managerEdit.prevId = managerEdit.manager.id;
          managerEdit.editOk = true;
        }
      }catch(e){
        console.log(e);
      }
    };

    const managerPwOverrideClick = async () =>{
      try{
        if(!(await backend({
          action: 'managerPwOverride',
          managerId: managerEdit.manager.id,
          pw: managerEdit.pw
        })).isError){
          managerEdit.overrideOk = true;
        }
      }catch(e){
        console.log(e);
      }
    };

    return { loginInfo, managerEdit, managerEditClick, managerPwOverrideClick };
  }
}
</script>
